<template>
  <div class="container_box details_box_styles">
    <!--返回按钮-->
    <div class="go_back" @click="goBackBtn">
      <Icon class="back_icon" type="ios-arrow-back"/>
      <span class="back_txt">{{ $t('key1001867') }}</span>
    </div>
    <!--拣货单详情-->
    <div class="basic_information_box">
      <Card :bordered="false" dis-hover>
        <template #title>
          <h2 class="title_box">{{ $t('key1002455') }}</h2>
        </template>
        <Row type="flex" :gutter="gutterItem">
          <Col :span="ItemCol" class="basic_item">
            <span class="basic_title">{{ $t('key1002456') }}</span>
            <span class="basic_txt">{{ detailInfo.pickingGoodsNo }}</span>
          </Col>
          <Col :span="ItemCol" class="basic_item">
            <span class="basic_title">{{ $t('key1000183') }}</span>
            <span class="basic_txt">{{ detailInfo.packageGoodsType }}</span>
          </Col>
          <Col :span="ItemCol" class="basic_item">
            <span class="basic_title">{{ $t('key1002457') }}</span>
            <span class="basic_txt">{{ detailInfo.packageGoodsStatus }}</span>
          </Col>
          <Col :span="ItemCol" class="basic_item">
            <span class="basic_title">{{ $t('key1002458') }}</span>
            <span class="basic_txt">{{ detailInfo.print }}</span>
          </Col>
          <Col :span="ItemCol" class="basic_item">
            <span class="basic_title">{{ $t('key1003721') }}</span>
            <span class="basic_txt">{{ detailInfo.remark }}</span>
          </Col>
          <Col :span="ItemCol" class="basic_item">
            <span class="basic_title color_style" @click="printList" v-if="
                getPermission('wmsPickingGoods_print') ||
                  getPermission('wmsPickingGoods_print_other')
              ">{{ $t('key1002459') }}</span>
          </Col>
          <Col :span="ItemCol" class="basic_item">
            <span class="basic_title">{{ $t('key1000181') }}</span>
            <span class="basic_txt">{{ detailInfo.warehouseName }}</span>
          </Col>
          <Col :span="ItemCol" class="basic_item">
            <span class="basic_title">{{ $t('key1000870') }}</span>
            <span class="basic_txt">{{ detailInfo.createdTime }}</span>
          </Col>
          <Col :span="ItemCol" class="basic_item">
            <span class="basic_title">{{ $t('key1002460') }}</span>
            <span class="basic_txt">{{ detailInfo.createdBy }}</span>
          </Col>
          <Col :span="ItemCol" class="basic_item">
            <span class="basic_title">{{ $t('key1003444') }}</span>
            <span class="basic_txt">{{ detailInfo.labelTypeText }}</span>
          </Col>
        </Row>
      </Card>
    </div>
    <!--功能按钮操作区域-->
    <div class="operation_box">
      <div class="mr15 sort_options_box">
        <Button-group style="margin-right: 10px">
          <Button
            :type="item.check ? 'primary' : 'default'"
            v-for="item in watchTypeData"
            @click="clickTypeButton(item)"
            :key="item.value">{{ item.label }}
          </Button>
        </Button-group>
      </div>
      <div v-if="formItem.type === '0'">
        <Input v-model.trim="formItem.sku" clearable style="width: 300px" :placeholder="$t('key1002453')"></Input>
        <Button type="primary" @click="btnSearch">{{ $t('key1000269') }}</Button>
      </div>
    </div>
    <!--列表-->
    <Table highlight-row border :columns="columns" :data="data" max-height="500"></Table>
    <!--确认删除最后一条-->
    <Modal v-model="dltLast" :title="$t('key1002454')" @on-ok="dltLastOk">
      <p style="font-size: 14px;font-weight: 600">{{ $t('key1002461') }}</p>
    </Modal>
  </div>
</template>

<script>
import { alias689d3582fa8c4a7891f9d6ea8bcd03a9 } from '@/customFolder/customVueAlias.js';

import Mixin from '@/components/mixin/common_mixin';
import api from '@/api/';

export default {
  name: "pickListDetails",
  mixins: [Mixin],
  data() {
    let v = this;
    return {
      ItemCol: 8,
      tableItemTotal: 0,
      dltLast: false,
      detailInfo: {
        pickingGoodsNo: '',
        packageGoodsStatus: '',
        packageGoodsType: '',
        print: '',
        warehouseName: '',
        createdTime: '',
        createdBy: '',
        remark: '',
        labelTypeText: ''
      },
      formItem: {
        type: '0',
        sku: null,
        pageNum: 1,
        pageSize: 10
      },
      watchTypeData: [
        {
          label: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1002462'),
          value: '0',
          check: true
        }, {
          label: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1002463'),
          value: '1',
          check: false
        }
      ],
      columns: [],
      data: [],
      columnsStockOut: [
        {
          type: 'index',
          width: 60,
          align: 'center'
        }, {
          title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000916'),
          key: 'pickingNo',
          align: 'center',
          render: (h, params) => {
            if (params.row.packageGoodsRelationStatus === '3') {
              return h('div', [
                h('span', params.row.pickingNo), h('span', {
                  style: {
                    color: 'red',
                    marginLeft: '5px'
                  }
                }, alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1005993'))
              ]);
            } else {
              return h('div', params.row.pickingNo);
            }
          }
        }, {
          title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1002497'),
          align: 'center',
          key: 'type'
        }, {
          title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1002464'),
          align: 'center',
          key: 'packageStatus',
          render: (h, params) => {
            let text = '';
            let status = Number(params.row.packageStatus);
            let salePickingList = [
              {value: 5, name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1002510')},
              {value: 6, name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1002511')},
              {value: 7, name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001204')},
              {value: 8, name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1003268')},
              {value: 99, name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001221')},
            ];
            salePickingList.map((item) => {
              if (item.value === status) {
                text = item.name;
              }
            });
            return h('span', text);
          }
        },
        {
          title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1002465'),
          align: 'center',
          key: 'packageUploadCarrierStatus',
          render: (h, params) => {
            let text = '';
            let status = params.row.packageUploadCarrierStatus;
            let packageUploadCarrierList = [
              {value: 0, name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1002466')},
              {value: 3, name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001054')},
              {value: 4, name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001055')}
            ];
            packageUploadCarrierList.map((item) => {
              if (item.value === status) {
                text = item.name;
              }
            });
            return h('div', {
              style: {
                color: status === 3 ? '#008000' : '#FF0000'
              }
            }, text);
          }
        },
        {
          title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000252'),
          align: 'center',
          key: 'warehouseName'
        }, {
          title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000685'),
          align: 'center',
          key: 'address',
          render: (h, params) => {
            if (params.row.pickingProductResultList) {
              return h('ul', params.row.pickingProductResultList.map(val => {
                return h('li', val.goodsSku + '*' + val.expectedNumber);
              }));
            }
          }
        }
      ],
      columnsGoods: [
        {
          type: 'index',
          width: 60,
          align: 'center'
        }, {
          title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1005994'),
          key: 'warehouseBlockGroupName'
        }, {
          title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000189'),
          key: 'warehouseBlockName'
        }, {
          title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000190'),
          key: 'warehouseLocationName'
        }, {
          title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1002470'),
          key: 'goodsUrl',
          minWidth: 120,
          render: (h, params) => {
            return v.tableImg(h, params, 'goodsUrl');
          }
        }, {
          title: 'SKU',
          key: 'goodsSku'
        }, {
          title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000192'),
          key: 'goodsCnDesc'
        }, {
          title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000195'),
          key: 'receiptBatchNo'
        }, {
          title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1005995'),
          key: 'expectedNumber'
        }, {
          title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1002472'),
          key: 'goodsVolume'
        }, {
          title: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000710') + '(g)',
          key: 'goodsWeight'
        }
      ],
      StockOutData: [],
      GoodsData: [],
      pickingGoodsNo: null,
      pickingStatus: null,
      where: 'sell'
    };
  },
  created() {
    let data = this.$route.query;
    if (data) {
      this.where = data.where;
      this.pickingStatus = data.pickingStatus;
      this.pickingGoodsNo = data.pickingGoodsNo;
    }
    if (this.pickingGoodsNo) {
      this.searchDtl(data => {
        this.StockOutData = this.processData(data);
        this.data = this.StockOutData; // 出库单数据
      });
    }
    if (this.pickingStatus === '1') {
      this.columnsStockOut.pop();
    }
    if (this.where === 'other') {
      this.columnsStockOut.splice(4, 1);
    }
    this.columns = this.columnsStockOut; // 出库单表头
  },
  methods: {
    // 返回列表
    goBackBtn() {
      this.$router.push('/wms/pickList');
    },
    clickTypeButton(data) {
      // 按分配切换不同的表格
      this.formItem.pageNum = 1;
      this.watchTypeData.forEach(function (n) {
        n.check = data.value === n.value;
      });
      this.formItem.type = data.value;
      if (data.value === '1') {
        this.columns = this.columnsGoods;
        this.searchOfGood();
      } else {
        this.columns = this.columnsStockOut;
        this.searchOfStockOut();
      }
    },
    backList() {
      this.$parent.workShow = 'list';
    },
    processData(listData) {
      // 返回数据的中文匹配
      let pickingTypeList = [
        {value: 'S1', name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001244')},
        {value: 'S2', name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001245')},
        {value: 'S3', name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001246')},
      ];
      listData.forEach(val => {
        pickingTypeList.map((item) => {
          if (val.pickingType === item.value) {
            val.type = item.name;
          }
        });
        val.pickStatus = val.packageGoodsStatus === '0' ? alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1002473') : val.packageGoodsStatus === '1' ? alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001224') : '';
      });
      return listData;
    },
    paramsObj() {
      return {
        pickingGoodsNo: this.pickingGoodsNo,
        status: this.formItem.type,
        pickingNo: this.formItem.sku ? this.formItem.sku : null,
        pageNum: this.formItem.pageNum,
        pageSize: this.formItem.pageSize
      };
    },
    // 按出库单查找
    searchOfStockOut() {
      this.searchDtl(data => {
        this.StockOutData = this.processData(data);
        this.GoodsData = [];
        this.data = this.StockOutData; // 出库单数据
      });
    },
    // 按商品查
    searchOfGood() {
      this.searchDtl(data => {
        this.GoodsData = this.processData(data);
        this.StockOutData = [];
        this.data = this.GoodsData; // 商品数据
      });
    },
    btnSearch() {
      if (this.formItem.type === '0') {
        this.searchOfStockOut();
      } else {
        this.searchOfGood();
      }
    },
    searchDtl(callback) {
      let obj = this.paramsObj();
      let v = this;
      this.axios.post(api.post_wmsPickingGoods_detail, obj).then(res => {
        if (res.data.code === 0) {
          let data = res.data.datas[0];
          this.detailInfo.pickingGoodsNo = data.pickingGoodsNo;
          this.detailInfo.remark = data.remark;
          this.detailInfo.packageGoodsStatus = data.packageGoodsStatus === '1' ? alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1001224') : data.packageGoodsStatus === '0' ? alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1002473') : '';
          let list = [
            {value: 'SS', name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000179')},
            {value: 'SM', name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000179')},
            {value: 'MM', name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000178')},
            {value: 'MIX', name: alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1005754')}
          ];
          list.map((item) => {
            if (item.value === data.packageGoodsType) {
              this.detailInfo.packageGoodsType = item.name;
            }
          })
          this.detailInfo.print = data.print === '0' ? alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000466') : alias689d3582fa8c4a7891f9d6ea8bcd03a9.t('key1000467');
          this.detailInfo.warehouseName = data.warehouseName;
          this.detailInfo.createdTime = this.$uDate.getDataToLocalTime(data.createdTime, 'fulltime');
          this.detailInfo.createdBy = data.createdByName;
          v.labelTypeList.map((item) => {
            if (item.value === data.labelType) {
              if (item.value === 'null') {
                this.detailInfo.labelTypeText = '-';
              } else {
                this.detailInfo.labelTypeText = item.title;
              }

            }
          });
          if (this.formItem.type === '0') {
            this.tableItemTotal = res.data.datas[0].queryByPickingResultPage.total;
            callback && callback(res.data.datas[0].queryByPickingResultPage.list);
          } else {
            this.tableItemTotal = res.data.datas[0].queryByGoodsDetailResultPage.total;
            callback && callback(res.data.datas[0].queryByGoodsDetailResultPage.list);
          }
        }
      });
    },
    dltLastOk() {
      let obj = this.StockOutData[0];
      this.axios.post(api.post_wmsPickingGoods_modifyPickingByNo + '?pickingNo=' +
        obj.pickingNo + '&pickingGoodsNo=' + this.detailInfo.pickingGoodsNo).then(res => {
        if (res.data.code === 0) {
          this.StockOutData.splice(0, 1);
          this.$parent.workShow = 'list';
        }
      });
    },
    printList() {
      let goto = this.$router.resolve({
        path: '/printPickList',
        query: {
          data: this.pickingGoodsNo,
          type: 'pickList',
          where: this.where || null
        }
      });
      window.open(goto.href, '_blank');
    }
  },
};
</script>

<style lang="less" scoped>
.details_box_styles {
  .color_style {
    color: #2D8CF0;
    font-weight: bold;
    cursor: pointer;
  }
}
</style>
